.cursor {
    cursor: pointer;
} 

.warm {
    color: rgb(255, 196, 0);
}

.current__stage {
    background-color:rgba(242, 255, 127, 0.137);
    color: rgb(255, 255, 255);
}


.good {
    color: rgb(95, 230, 95);
}

.bad {
    color: rgb(221, 55, 55);
}

.unload.good {
    background-color: rgb(79, 102, 34);
    color:white;
}

.unload.good:hover {
    background-color: rgb(136, 170, 68);
    color:white;
}

.unload.bad {
    background-color: rgb(102, 34, 34);
    color: white;
}

.total {
    background-color: rgb(20, 20, 20);;
    color: white;
}
/* .total:hover {
    background-color: gray;
    color: white;
} */

.pay {
    background-color: rgb(20, 20, 20);
    color:rgb(255, 255, 255);
}
/* .pay:hover {
    background-color: orange;
} */

.segment {
    background: rgb(119, 32, 32);
}